import { Container } from "react-bootstrap";
import { Container as ContainerStyled } from "./styles";
import { useEffect, useState } from "react";
import Trade from "./Trade";
import Customize from "./Customize";
const Desktop = () => {
  const [active, setActive] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setActive((prevState) => (prevState === 2 ? 0 : prevState + 1));
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <ContainerStyled id="services">
      <Container className="inner">
        <nav className="nav">
          <div>
            <h2>Seamless Broker Connection: Connect with 30+ Brokers</h2>
            <p>
              Experience the future of trading with our platform. Connect
              effortlessly with 30+ top brokers, streamlining your investment
              journey and ensuring you never miss an opportunity.
            </p>
          </div>
        </nav>
        <div className="trade">
          <Trade />
        </div>
        <div className="trade">
          <Customize />
        </div>
      </Container>
    </ContainerStyled>
  );
};
export default Desktop;
