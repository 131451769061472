import Item from "./Item";
import { DesktopContainer, DesktopMainContainer } from "./styles";

const testimonials = [
  {
    text: "This algo trading platform is a game-changer! I've seen consistent profits since I started in 2021.",
    name: "Rahul Gupta",
    since: "TRADING ON BELIEVE SINCE 2021",
  },
  {
    text: "Believe's algorithmic trading has simplified my investment journey. Impressive results!",
    name: "Priya Sharma",
    since: "TRADING ON BELIEVE SINCE 2019",
  },
  {
    text: "I'm thrilled with the returns I've achieved with Believe's algo trading. Truly impressive!",
    name: "Amit Patel",
    since: "TRADING ON BELIEVE SINCE 2020",
  },
  {
    text: "Trustworthy and transparent. I've been trading with Believe since 2022 and never looked back.",
    name: "Sneha Reddy",
    since: "TRADING ON BELIEVE SINCE 2022",
  },
  {
    text: "A friend recommended Believe, and I'm glad I listened. Great returns and excellent support!",
    name: "Anil Kumar",
    since: "TRADING ON BELIEVE SINCE 2018",
  },
];

const Desktop = () => {
  return (
    <DesktopMainContainer>
      <h2>Don’t take our word for it</h2>
      <DesktopContainer>
        {testimonials.map((testimonial) => (
          <Item key={testimonial.name} {...testimonial} />
        ))}
      </DesktopContainer>
    </DesktopMainContainer>
  );
};
export default Desktop;
