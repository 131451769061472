import { Container } from "./ButtonStyles";

type Props = {
  text: string;
  variant: "outline" | "primary" | "square" | "secondary";
  height?: number;
  padding?: number;
  width?: string;
  type?: string;
  onClick: any;
};
const Button: any = ({
  text,
  variant,
  height = 38,
  padding = 12,
  width,
  type,
  onClick,
}: Props) => {
  return (
    <Container
      height={height}
      variant={variant}
      padding={padding}
      width={width}
      type={type}
      onClick={onClick}
    >
      {text}
    </Container>
  );
};
export default Button;
