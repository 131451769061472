import { useEffect, useState } from "react";
import { Content, SliderMobileContainer } from "./styles";
import { motion } from "framer-motion";
import RenderIf from "../RenderIf";

type Props = {
  sliderItems: { name: string; content: string; image: string }[];
  lastIndex?: number;
};
const SliderMobile = ({ sliderItems, lastIndex = 2 }: Props) => {
  const [active, setActive] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setActive((prevActive) =>
        prevActive === lastIndex ? 0 : prevActive + 1
      );
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <SliderMobileContainer>
      <div className="head">
        <ul>
          {sliderItems.map((item, index) => (
            <li key={item.name}>
              <h6 className={active === index ? "active" : "heading__h6"}>
                {item.name}
              </h6>
              <RenderIf condition={active === index}>
                <motion.div
                  initial={{ width: 0 }}
                  animate={{ width: 63 }}
                  transition={{ duration: 0.4 }}
                  className="indicator"
                />
              </RenderIf>
            </li>
          ))}
        </ul>
      </div>
      <Content>
        <div>
          <p>{sliderItems[active]?.content}</p>
        </div>
        <img
          style={{ width: "100%" }}
          src={sliderItems?.[active]?.image}
          alt=""
        />
      </Content>
    </SliderMobileContainer>
  );
};
export default SliderMobile;
