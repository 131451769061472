import styled, { css } from "styled-components";
export const DesktopContainer = styled.div<any>`
  width: 100%;
  padding: 24px 5%;
  border-radius: 20px;
  max-width: 1397px;
  margin: 44px auto 0;
  @media (max-width: 996px) {
    width: 90%;
  }
  ${({ active }) => {
    if (active === 0)
      return css`
        background-color: #fad0c0;
      `;
    if (active === 1)
      return css`
        background-color: #e1fffc;
      `;
    if (active === 2)
      return css`
        background-color: #b1aefe;
      `;
  }}
`;
export const MainContainer = styled.div`
  .main_heading {
    font-size: 24px;
    color: #41246e;
    line-height: 1.3;
    letter-spacing: -0.035em;
    font-weight: 700;
    margin-top: 0;
    text-align: center;
    margin-bottom: 16px;
    width: 100%;
    box-sizing: border-box;
    @media (max-width: 996px) {
      font-size: 18px;
    }
  }
  .main_text {
    margin: 0;
    text-align: center;
    width: 50%;
    color: #666;
    margin-bottom: 24px;
    @media (max-width: 996px) {
      width: 90%;
      font-size: 14px;
    }
  }
  .inner {
    max-width: 1170px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .nav {
    top: 118px;
    padding-bottom: 0;
    position: sticky;
    top: 70px;
    z-index: 1;
    width: 100%;
  }
  .nav ul {
    display: flex;
    border-bottom: 1px solid #f2ecfd;
    position: relative;
    justify-content: center;
    margin: 0 auto;
    background-color: var(--white);
    z-index: 10;
    padding: 0;
    list-style: none;
    width: 100%;
    align-items: center;
    &::after {
      content: "";
      display: inline-block;
      min-height: 118px;
      width: 100%;
      position: absolute;
      top: 100%;
      z-index: 1;
    }
    h2 {
      font-size: 24px;
      color: #41246e;
      line-height: 1.3;
      letter-spacing: -0.035em;
      font-weight: 700;
      margin-top: 0;
      text-align: center;
      margin-bottom: 24px;
    }
    p {
      margin: 0;
      text-align: center;
      width: 80%;
      color: #666;
      margin-bottom: 20px;
    }
  }
  .nav li {
    padding: 24px 38px;
    cursor: pointer;
    box-sizing: border-box;
    a {
      font-size: 20px;
      color: ${({ theme: { brandDark } }) => brandDark};
      font-weight: 600;
      line-height: 1.25;
      letter-spacing: -0.035em;
      margin: 0;
      opacity: 0.3;
      text-decoration: none;
      @media (max-width: 996px) {
        font-size: 18px;
      }
    }
  }
  .nav .active a {
    opacity: 1;
  }
  margin: 124px auto;
`;
export const Container = styled.div<any>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 124px 0;
  width: 100%;
  @media (max-width: 996px) {
    padding: 62px 0 12px;
  }
  h2 {
    line-height: 1.3;
    letter-spacing: -0.035em;
    font-weight: 700;
    margin-top: 0;
    font-size: 30px;
    margin-bottom: 12px;
    text-align: left;
    width: 100%;
    ${({ active }) => {
      if (active === 0) {
        return css`
          color: #f07242;
        `;
      }
      if (active === 1) {
        return css`
          color: #00ccb8;
        `;
      }
      if (active === 2) {
        return css`
          color: #3d35fd;
        `;
      }
    }}
    @media (max-width: 996px) {
      text-align: center;
      margin-bottom: 12px;
      font-size: 24px;
    }
  }
  p {
    margin-bottom: 20px;
    line-height: 1.5;
    letter-spacing: -0.01em;
    color: rgba(61, 61, 61, 0.75);
    font-size: 16px;
    text-align: left;
    @media (max-width: 996px) {
      text-align: justify;
      font-size: 14px;
      width: 90%;
    }
  }
`;
