import { Col, Row } from "react-bootstrap";
import Button from "../Button";
import { Container } from "./styles";
import useWindowDimensions from "../hooks/useWindowDimensions";
import RenderIf from "../RenderIf";
import { useState, useEffect } from "react";
import emailjs from "@emailjs/browser";

const Contact = () => {
  const { width } = useWindowDimensions();
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [finished, setFinished] = useState(false);
  const handleSubmit = async () => {
    const serviceId = "service_wodxbpd";
    const templateId = "template_xje9i59";
    try {
      setLoading(true);
      await emailjs.send(serviceId, templateId, {
        from_name: fullName,
        message: message,
        email: email,
        phone: phone,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      setFinished(true);
      setFullName("");
      setEmail("");
      setPhone("");
      setMessage("");
    }
  };
  useEffect(() => {
    let interval: any;
    if (finished) {
      interval = setTimeout(() => {
        setFinished(false);
      }, 3000);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [finished]);
  useEffect(() => emailjs.init("XqThhQHFJ-5N0D1KV"), []);

  return (
    <Container id="contact">
      <Row>
        <Col xs={12} lg={7}>
          <h2>Let's Get Connected</h2>
          <p>
            If you have any query or confusion, feel free to contact us. You can
            also fill the form give below to get a call back from us!
          </p>
          <div className="form">
            <input
              placeholder="Full Name"
              onChange={(e) => setFullName(e.target.value)}
              value={fullName}
            />
            <input
              placeholder="Email Address"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
            <input
              placeholder="Mobile Number"
              onChange={(e) => setPhone(e.target.value)}
              value={phone}
            />
            <textarea
              placeholder="Message"
              onChange={(e) => setMessage(e.target.value)}
              value={message}
            />
            <Button
              variant="square"
              text={finished ? "Sent" : "Send Message"}
              width={"100%"}
              height={45}
              onClick={() => handleSubmit()}
            />
          </div>
        </Col>
        <RenderIf condition={width > 995}>
          <Col lg={5}>
            <img style={{ width: "100%" }} src="images/contact.svg" alt="" />
          </Col>
        </RenderIf>
      </Row>
    </Container>
  );
};
export default Contact;
