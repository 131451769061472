import { Col, Row } from "react-bootstrap";
import { DesktopContainer } from "./styles";
import { MainContainer } from "./styles";
import { useState } from "react";
import MQL from "./MQL";
import RenderIf from "../RenderIf";
import Pine from "./Pine";
import WebApp from "./WebApp";
import useWindowDimensions from "../hooks/useWindowDimensions";
const urls = [
  "/images/mql.svg",
  "/images/pine-script.svg",
  "/images/web-app.svg",
];

const Code = () => {
  const [active, setActive] = useState(0);
  const { width } = useWindowDimensions();
  return (
    <MainContainer>
      <Row
        style={{
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          margin: 0,
        }}
      >
        <h2 className="main_heading">
          We turn imaginations into reality through expert coding
        </h2>
        <p className="main_text">
          Elevate your digital presence with our tailored strategy, website, and
          app development services. Contact us to bring your vision to life.
        </p>
      </Row>
      <Row className="inner">
        <nav className="nav">
          <ul>
            <li
              onClick={() => setActive(0)}
              className={active === 0 ? "active" : ""}
            >
              <a>MQL</a>
            </li>
            <li
              onClick={() => setActive(1)}
              className={active === 1 ? "active" : ""}
            >
              <a>Pine Script</a>
            </li>
            <li
              onClick={() => setActive(2)}
              className={active === 2 ? "active" : ""}
            >
              <a>Web / App</a>
            </li>
          </ul>
        </nav>
      </Row>
      <DesktopContainer active={active}>
        <Row>
          <Col
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
            lg={6}
          >
            <img
              style={{
                width: width > 995 ? "95%" : "60%",
                maxWidth: "100%",
                height: "auto",
                display: "block",
                margin: "0 auto",
              }}
              src={urls[active]}
              alt=""
            />
          </Col>
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            lg={6}
          >
            <RenderIf condition={active === 0}>
              <MQL />
            </RenderIf>
            <RenderIf condition={active === 1}>
              <Pine />
            </RenderIf>
            <RenderIf condition={active === 2}>
              <WebApp />
            </RenderIf>
          </Col>
        </Row>
      </DesktopContainer>
    </MainContainer>
  );
};
export default Code;
