import { Container } from "./styles";

const Pine = () => {
  return (
    <Container active={1}>
      <p style={{ fontSize: "17px", marginBottom: "32px" }}>
        Transform your trading strategies into reality with our Pine Script
        coding services. Pine Script is the scripting language used for custom
        indicator and strategy development on TradingView, and our team of
        skilled Pine Script developers is here to help you harness its full
        potential.
      </p>
      <h2 style={{ fontSize: "20px" }}>Custom Indicators:</h2>
      <p style={{ fontSize: "17px", marginBottom: "32px" }}>
        Gain a competitive edge with personalized indicators that precisely
        reflect your trading strategy. Whether you need oscillators, moving
        averages, or complex pattern recognition tools, we'll craft indicators
        that align seamlessly with your unique goals and market analysis.
      </p>
      <h2 style={{ fontSize: "20px" }}>Strategy Development:</h2>
      <p style={{ fontSize: "17px", marginBottom: "24px" }}>
        Automate your trading decisions and execute them with precision using
        custom Pine Script strategies. Our developers will work closely with you
        to create strategies that encompass your specific entry and exit
        criteria, risk management, and more.
      </p>
    </Container>
  );
};
export default Pine;
