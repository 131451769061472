import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 64px 0;
  width: 90%;
  margin: 0 auto;
  @media (max-width: 996px) {
    background-color: #f2ffe1;
    background-image: repeating-linear-gradient(
        -19deg,
        #d3ff99,
        #d3ff99 8.6px,
        transparent 10px,
        transparent 16.02px,
        #d3ff99 17px
      ),
      repeating-linear-gradient(
        71deg,
        #d3ff99,
        #d3ff99 8.6px,
        transparent 10px,
        transparent 16.02px,
        #d3ff99 17px
      ),
      repeating-linear-gradient(
        161deg,
        #d3ff99,
        #d3ff99 8.6px,
        transparent 10px,
        transparent 16.02px,
        #d3ff99 17px
      ),
      repeating-linear-gradient(
        251deg,
        #d3ff99,
        #d3ff99 8.6px,
        transparent 10px,
        transparent 16.02px,
        #d3ff99 17px
      );
    background-size: 3px 100%, 100% 3px, 3px 100%, 100% 3px;
    background-position: 0 0, 0 0, 100% 0, 0 100%;
    background-repeat: no-repeat;
    border-radius: 20px;
  }
  h2 {
    color: #74cc00;
    line-height: 1.3;
    letter-spacing: -0.035em;
    font-weight: 700;
    margin-top: 0;
    font-size: 30px;
    margin-bottom: 12px;
    text-align: left;
    width: 100%;
    @media (max-width: 996px) {
      text-align: center;
      font-size: 24px;
      margin-bottom: 24px;
    }
  }
  p {
    margin-bottom: 20px;
    line-height: 1.5;
    letter-spacing: -0.01em;
    color: rgba(61, 61, 61, 0.75);
    font-size: 16px;
    text-align: left;
    @media (max-width: 996px) {
      text-align: left;
      font-size: 14px;
      width: 90%;
    }
  }
`;
export const DesktopContainer = styled.div`
  width: 100%;
  padding: 24px 10%;
  background-color: #f2ffe1;
  background-image: repeating-linear-gradient(
      -19deg,
      #d3ff99,
      #d3ff99 8.6px,
      transparent 10px,
      transparent 16.02px,
      #d3ff99 17px
    ),
    repeating-linear-gradient(
      71deg,
      #d3ff99,
      #d3ff99 8.6px,
      transparent 10px,
      transparent 16.02px,
      #d3ff99 17px
    ),
    repeating-linear-gradient(
      161deg,
      #d3ff99,
      #d3ff99 8.6px,
      transparent 10px,
      transparent 16.02px,
      #d3ff99 17px
    ),
    repeating-linear-gradient(
      251deg,
      #d3ff99,
      #d3ff99 8.6px,
      transparent 10px,
      transparent 16.02px,
      #d3ff99 17px
    );
  background-size: 3px 100%, 100% 3px, 3px 100%, 100% 3px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
  border-radius: 20px;
  max-width: 1397px;
  margin: 124px auto;
  .about_heading {
    color: ${({ theme: { brandDark } }) => brandDark};
    line-height: 1.3;
    letter-spacing: -0.035em;
    font-weight: 700;
    margin-top: 16px;
    font-size: 32px;
    margin-bottom: 24px;
    text-align: center;
    width: 100%;
    @media (max-width: 996px) {
      text-align: center;
      margin-bottom: 0;
      font-size: 24px;
    }
  }
`;
