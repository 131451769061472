import useWindowDimensions from "../hooks/useWindowDimensions";
import { Container } from "./styles";

const MQL = () => {
  const { width } = useWindowDimensions();
  return (
    <Container active={0}>
      <p
        style={{
          fontSize: "17px",
          marginBottom: "32px",
          textAlign: width < 996 ? "justify" : "left",
        }}
      >
        Elevate your trading experience with our specialized MQL (MetaQuotes
        Language) development services. At Believe, we are dedicated to helping
        traders like you achieve their financial goals through custom Indicator
        and Expert Advisor (EA) development for MetaTrader platforms.
      </p>
      <h2 style={{ fontSize: "20px" }}>Customized Indicators:</h2>
      <p style={{ fontSize: "17px", marginBottom: "32px" }}>
        Indicators are the bedrock of effective technical analysis. Our expert
        MQL developers craft bespoke indicators tailored to your unique trading
        strategy. Whether you're a scalper, day trader, or long-term investor,
        we create indicators that align seamlessly with your objectives. Say
        goodbye to generic solutions and hello to precision.
      </p>
      <h2 style={{ fontSize: "20px" }}>Expert Advisors (EAs):</h2>
      <p style={{ fontSize: "17px", marginBottom: "24px" }}>
        Imagine having a trusted assistant managing your trades 24/7, adhering
        strictly to your predefined rules. Our EAs do just that. We develop EAs
        that are highly customizable, rigorously tested, and designed to perform
        consistently under varying market conditions. Embrace systematic,
        emotion-free trading with our EA solutions.
      </p>
    </Container>
  );
};
export default MQL;
