import Button from "../Button";
import { Container, Links } from "./styles";
const Desktop = () => {
  return (
    <Container>
      <div>
        <img src="/images/logo.svg" />
        <Links>
          <li>
            <a href="#home">Home</a>
          </li>
          <li>
            <a href="#services">Services</a>
          </li>
          <li>
            <a href="#about">About</a>
          </li>
          <li>
            <a href="#strategies">Strategies</a>
          </li>
          <li>
            <a href="#contact">Contact</a>
          </li>
        </Links>
        <div>
          <Button
            text="Create An Account"
            variant="primary"
            onClick={() =>
              (window.location.href = "https://panel.btalgo.in/signup")
            }
          />
          <Button
            text="Sign In"
            variant="outline"
            onClick={() =>
              (window.location.href = "https://panel.btalgo.in/login")
            }
          />
        </div>
      </div>
    </Container>
  );
};
export default Desktop;
