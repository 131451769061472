import { ItemContainer } from "./styles";
type Props = {
  text: string;
  name: string;
  since: string;
};
const Item = ({ text, name, since }: Props) => {
  console.log(name);
  return (
    <ItemContainer>
      <p>{text}</p>
      <figure>
        <img src="/images/avatar.svg" alt="User Icon" />
      </figure>
      <h6>{name}</h6>
      <label>{since}</label>
    </ItemContainer>
  );
};
export default Item;
