import styled from "styled-components";

export const ItemContainer = styled.div<any>`
  transition: 0.5s;
  text-align: center;
  background-color: #f8f7f5;
  border-radius: 8px;
  padding: 32px 51px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  width: 25%;
  &:hover {
    background-color: ${({ theme: { brandLight } }) => brandLight};
  }
  @media (max-width: 996px) {
    padding: 25px;
    width: 44%;
    height: 200px;
    margin: 0;
    display: flex;
  }
`;
export const Heading = styled.div`
  font-size: 18px;
  color: ${({ theme: { brandDark } }) => brandDark};
  font-weight: 600;
  line-height: 1.44;
  letter-spacing: -0.01em;
  position: relative;
  margin: 8px 0;
`;
export const Text = styled.div`
  font-size: 14px;
  line-height: 1.5;
  color: #000;
  letter-spacing: -0.01em;
  position: relative;
  margin-right: 15px;
  padding: unset;
  display: unset;
  margin-bottom: 0;
  opacity: 1;
`;

export const ItemsContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  @media (max-width: 996px) {
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    gap: 12px;
  }
`;

export const Container = styled.div`
  margin: 98px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 36px;
  & > h2 {
    font-size: 32px;
    color: ${({ theme: { brandDark } }) => brandDark};
    line-height: 1.3;
    letter-spacing: -0.035em;
    font-weight: 700;
    margin-top: 0;
    text-align: center;
  }
  .payment {
    padding: 24px 0;
    background-color: #e2fbff;
    width: 100%;
    box-sizing: border-box;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 0 auto;
    border-radius: 50px;
    position: relative;
    overflow: hidden;
    @media (max-width: 996px) {
      width: 95%;
      border-radius: 10px;
    }
    .city-bg {
      position: absolute;
      width: 80%;
      left: 10%;
      bottom: -250px;
      opacity: 0.1;
      z-index: -1;
    }
    .head {
      position: relative;
      z-index: 1;
      h2,
      p {
        text-align: center;
      }
      p {
        width: 50%;
        margin: 0 auto;
        text-align: center;
        color: #666;
        margin-bottom: 24px;
        @media (max-width: 996px) {
          width: 90%;
          margin-top: 12px;
        }
      }
      h2 {
        font-size: 32px;
        color: #41246e;
        line-height: 1.3;
        letter-spacing: -0.035em;
        font-weight: 700;
        margin-top: 0;
        text-align: center;
        @media (max-width: 996px) {
          font-size: 24px;
        }
      }
    }
    .body {
      position: relative;
      z-index: 1;
      padding: 48px 0;
      width: 100%;
      box-sizing: border-box;
      @media (max-width: 996px) {
        margin: 0 auto;
      }
      .left h3 {
        font-size: 20px;
        text-align: center;
        color: #41246e;
        margin-bottom: 12px;
        @media (max-width: 996px) {
          font-size: 16px;
        }
      }
      .icons {
        margin: 12px 0;
      }
      .right {
        box-sizing: border-box;
        min-height: 100%;
        justify-content: center;
        align-items: center;
        display: flex;
        .scanner {
          width: 250px;
          height: 250px;
          border-radius: 15px;
          background-color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 230px;
            height: 230px;
          }
        }
        .bank-details {
          width: 300px;
          height: 300px;
          border-radius: 20px;
          background-color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          ul {
            margin: 0;
          }
          li {
            list-style: none;
            margin: 6px 0;
            padding: 0;
          }
        }
      }
      .left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        @media (min-width: 996px) {
          border-right: 1px solid #999;
        }
        .icons {
          display: flex;
          gap: 12px;
          align-items: center;
          justify-content: center;
          img {
            width: 24px;
            height: 24px;
          }
        }
        .note {
          text-align: center;
          width: 85%;
          margin: 12px auto;
          color: ${({ theme: { brandDark } }) => brandDark};
          font-size: 14px;
          @media (max-width: 996px) {
            width: 95%;
          }
        }
        .or {
          margin: 12px auto;
        }
        .no-upi {
          width: 100%;
          margin: 12px auto 48px;
        }
        .no-upi-span {
          color: #74cc00;
        }
        .no-upi:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
`;
