import Button from "../Button";
import { Container, StyledInput } from "./styles";
import React, { useEffect, useState } from "react";
import emailjs from "@emailjs/browser";
import useWindowDimensions from "../hooks/useWindowDimensions";
import RenderIf from "../RenderIf";
type Props = {
  setEMailSend: any;
};
const Input = ({ setEMailSend }: Props) => {
  const [phone, setPhone] = useState("");
  const [input, setInput] = useState("");
  const { width } = useWindowDimensions();
  const [loading, setLoading] = useState(false);
  const [finished, setFinished] = useState(false);
  const [step, setStep] = useState(1);
  const handleSubmit = async () => {
    const serviceId = "service_wodxbpd";
    const templateId = "template_49ibs92";
    try {
      setLoading(true);
      await emailjs.send(serviceId, templateId, {
        from_name: input,
        message: phone,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      setFinished(true);
      setEMailSend(true);
    }
  };
  const handleNext = () => {
    setPhone(input);
    setStep(2);
    setInput("");
  };
  useEffect(() => emailjs.init("XqThhQHFJ-5N0D1KV"), []);

  return (
    <Container>
      <StyledInput
        style={{
          width: step === 2 ? "75%" : "100%",
          marginRight: step === 2 ? 0 : "12px",
          paddingLeft: step === 2 ? "24px" : "64px",
        }}
        value={input}
        onChange={(e) => setInput(e.target.value)}
        placeholder={
          step === 1 ? "Enter your mobile number" : "Enter your full name"
        }
      />
      <Button
        type="submit"
        text={step === 1 ? "Next" : "Get Call"}
        variant="primary"
        height={54}
        padding={24}
        onClick={() => (step === 1 ? handleNext() : handleSubmit())}
        width={width < 996 ? "100%" : "fit-content"}
      />
      <RenderIf condition={step === 1}>
        <div className="country-code">+91</div>
      </RenderIf>
    </Container>
  );
};
export default Input;
