import NavClose from "../../icons/NavClose";
import NavOpen from "../../icons/NavOpen";
import Button from "../Button";
import RenderIf from "../RenderIf";
import { MobileContainer, MobileLinks } from "./styles";
import { useState } from "react";
const Mobile = () => {
  const [opened, setOpened] = useState(false);
  return (
    <MobileContainer>
      <div>
        <img src="/images/logo.svg" />
        <div style={{ display: "flex", gap: "24px" }}>
          <Button
            text="Sign Up"
            variant="outline"
            onClick={() =>
              (window.location.href = "https://panel.btalgo.in/login")
            }
          />
          <div
            onClick={() => setOpened((prevState) => !prevState)}
            className="toggler"
          >
            {opened ? <NavClose /> : <NavOpen />}
          </div>
        </div>
      </div>
      <div style={{ display: opened ? "block" : "none" }}>
        <MobileLinks>
          <li>
            <a href="#home">Home</a>
          </li>
          <li>
            <a href="#services">Services</a>
          </li>
          <li>
            <a href="#about">About</a>
          </li>
          <li>
            <a href="#strategies">Strategies</a>
          </li>
          <li>
            <a href="#contact">Contact</a>
          </li>
        </MobileLinks>
      </div>
    </MobileContainer>
  );
};
export default Mobile;
