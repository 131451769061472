import { Col, Row } from "react-bootstrap";
import { DesktopContainer } from "./styles";
import Mobile from "./Mobile";

const Desktop = () => {
  return (
    <DesktopContainer id="about">
      <Row>
        <Col xs={12}>
          <h2 className="about_heading">
            Simplify trading, connect effortlessly.
          </h2>
        </Col>
      </Row>
      <Row>
        <Col
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
          lg={6}
        >
          <img
            style={{ width: "90%", maxWidth: "100%", height: "auto" }}
            src="/images/about-bg.svg"
            alt=""
          />
        </Col>
        <Col
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          lg={6}
        >
          <Mobile />
        </Col>
      </Row>
    </DesktopContainer>
  );
};
export default Desktop;
