import styled from "styled-components";
export const StyledInput = styled.input`
  border: 1px solid #444;
  box-shadow: none;
  border-radius: 32px;
  padding: 15px 16px 15px 64px;
  height: auto;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  position: relative;
  margin-bottom: 0;
  height: 54px;
  @media (max-width: 996px) {
    width: 100%;
  }
  box-sizing: border-box;
  &:active,
  &:focus {
    outline: none;
    border: 1px solid ${({ theme: { brand } }) => brand};
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  @media (max-width: 996px) {
    gap: 12px;
    flex-direction: column;
  }
  .country-code {
    background-color: #f9f9f9;
    padding: 8px 12px;
    border-radius: 24px;
    font-size: 14px;
    font-weight: 700;
    color: #444;
    line-height: 1.4;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    left: 8px;
    @media (max-width: 996px) {
      top: 27px;
      left: 10px;
    }
  }
`;
