import DownArrow from "../../icons/DownArrow";
import { ItemContainer } from "./styles";
import { motion, AnimatePresence } from "framer-motion";
type Props = {
  heading: string;
  answer: string;
  setActive: () => void;
  active: boolean;
};
const Item = ({ heading, answer, setActive, active }: Props) => {
  return (
    <ItemContainer>
      <div onClick={() => setActive()} className="heading">
        <p>{heading}</p>
        <DownArrow />
      </div>
      <AnimatePresence>
        {active && (
          <motion.div
            initial={{ height: 0 }}
            animate={{ height: "fit-content" }}
            exit={{ height: 0 }}
            className="answer"
          >
            <p>{answer}</p>
          </motion.div>
        )}
      </AnimatePresence>
    </ItemContainer>
  );
};
export default Item;
