import { Container } from "./styles";

const Mobile = () => {
  return (
    <Container id="about">
      <h2>Fast & Best Platform for Algo Trading</h2>
      <p style={{ fontSize: "17px" }}>
        At Believe, we are passionate about revolutionizing the world of trading
        through innovation, cutting-edge technology, and a deep commitment to
        our clients' success. With a focus on algorithmic trading in the Indian
        market, we have emerged as a trusted partner for traders seeking to
        optimize their portfolios and achieve their financial goals.
      </p>
      <p style={{ fontSize: "17px" }}>
        Our mission is clear: to empower traders with the tools, knowledge, and
        strategies needed to thrive in the dynamic world of algorithmic trading.
        We believe that every trader deserves access to advanced trading
        solutions that can level the playing field and unlock new opportunities
        in the market.
      </p>
    </Container>
  );
};
export default Mobile;
