import { useState } from "react";
import { Col } from "react-bootstrap";
import { Background, Container, TextContainer } from "./styles";
import Input from "../input";
import { Standout } from "./styles";
import RenderIf from "../RenderIf";
import useWindowDimensions from "../hooks/useWindowDimensions";

const Hero = () => {
  const { width } = useWindowDimensions();
  const [emailSend, setEMailSend] = useState(false);
  return (
    <Container id="home">
      <div>
        <Col md={7} xs={12}>
          <Background />
          <TextContainer>
            <h2>
              Unleash The Power Of Algorithms
              <br />
              <span>In Your Trading Strategy</span>
            </h2>
            <Input setEMailSend={setEMailSend} />
            <RenderIf condition={!emailSend}>
              <p className="terms">
                By filling your nummber and your name, we will get the data of
                yours. You will get a callback from the believe technology and
                Developers team. We may call within 24 hours!
              </p>
            </RenderIf>
            <RenderIf condition={emailSend}>
              <p className="send">
                We have recieved you request and will be proccessed within 24
                hours. Please feel free to contact us through the form below for
                any futher question.
              </p>
            </RenderIf>
            <Standout>
              <li>
                <div>
                  <h5>
                    500+
                    <span>
                      <img
                        src="images/star_icon.svg"
                        alt="star-icon"
                        width="14"
                        height="13"
                      />
                    </span>
                  </h5>
                  <span>Daily users</span>
                </div>
              </li>
              <li>
                <div>
                  <h5>1700+</h5>
                  <span>Trades everyday</span>
                </div>
              </li>
              <li>
                <div>
                  <h5>₹0</h5>
                  <span>Hidden fees</span>
                </div>
              </li>
              <li>
                <div>
                  <h5>30+</h5>
                  <span>Brokers</span>
                </div>
              </li>
            </Standout>
          </TextContainer>
        </Col>
        <RenderIf condition={width > 995}>
          <Col lg={5} xs={12}>
            <img className="hero-img" src="/images/hero-bg.svg" alt="Layout" />
          </Col>
        </RenderIf>
      </div>
    </Container>
  );
};
export default Hero;
