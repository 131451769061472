import styled from "styled-components";
export const Container = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
`;
export const ItemContainer = styled.div`
  padding: 28px 26px;
  width: 70%;
  border-radius: 10px;
  margin: 0 auto;
  background-color: ${({ theme: { brandLight } }) => brandLight};
  p {
    font-size: 16px;
    color: #444;
    font-weight: 300;
    margin-bottom: 25px;
    line-height: 1.3;
  }
  figure {
    margin: 0 0 8px;
    img {
      max-width: 100%;
      height: 48px;
    }
  }
  h6 {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: -0.01em;
    color: #3d3d3d;
    margin: 0;
    text-transform: capitalize;
  }
  label {
    font-size: 10px;
    color: ${({ theme: { brand } }) => brand};
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 0;
  }
`;
export const Indicators = styled.div`
  display: flex;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  align-items: center;
  justify-content: center;
  gap: 6px;
  & div {
    background-color: #f8f7f5;
    width: 6px;
    height: 6px;
    border: 1px solid ${({ theme: { brandDark } }) => brandDark};
    border-radius: 100px;
    cursor: pointer;
  }
  & .active {
    background-color: ${({ theme: { brandDark } }) => brandDark};
  }
`;

export const DesktopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
`;
export const DesktopMainContainer = styled.div`
  padding: 96px 5%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;
  h2 {
    color: ${({ theme: { brandDark } }) => brandDark};
    line-height: 1.3;
    letter-spacing: -0.035em;
    font-weight: 700;
    margin-top: 0;
    font-size: 32px;
    margin-bottom: 24px;
    text-align: center;
    width: 100%;
    @media (max-width: 996px) {
      text-align: center;
      margin-bottom: 0;
      font-size: 24px;
    }
  }
`;
