import styled, { css } from "styled-components";
export const Container = styled.button<any>`
  width: ${({ width }) => (width ? width : "fit-content")};
  padding: ${({ padding }) => `0 ${padding}px;`};
  color: #fff;
  text-align: center;
  font-weight: 700;
  line-height: 36px;
  cursor: pointer;
  border-radius: 100px;
  height: ${({ height }) => `${height}px`};
  ${({ variant }) =>
    variant === "primary"
      ? css`
          border: none;
          background-color: ${({ theme: { brand } }) => brand};
          &:hover {
            background-color: ${({ theme: { brandDark } }) => brandDark};
          }
        `
      : ""}
  ${({ variant }) =>
    variant === "outline"
      ? css`
          border: 1px solid ${({ theme: { brandDark } }) => brandDark};
          background-color: transparent;
          color: #43165c;
          &:hover {
            background-color: ${({ theme: { brandLight } }) => brandLight};
          }
        `
      : ""}
      ${({ variant }) =>
    variant === "square"
      ? css`
          border: none;
          background-color: ${({ theme: { brand } }) => brand};
          border-radius: 8px;
          width: 100%;
          &:hover {
            background-color: ${({ theme: { brandDark } }) => brandDark};
          }
        `
      : ""}
      ${({ variant }) =>
    variant === "squareSecondary"
      ? css`
          border: none;
          background-color: ${({ theme: { brand } }) => brand};
          border-radius: 8px;
          width: 100%;
          &:hover {
            background-color: ${({ theme: { brandDark } }) => brandDark};
          }
        `
      : ""}
`;
