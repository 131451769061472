import styled from "styled-components";

export const Container = styled.header`
  height: 70px;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  box-sizing: border-box;
  z-index: 100;
  & > div {
    box-sizing: border-box;
    width: 100%;
    max-width: 1392px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    & > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 12px;
      box-sizing: border-box;
    }
  }
  img {
    width: 130px;
    height: auto;
  }
`;
export const Links = styled.ul<any>`
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 14px;
  line-height: 60px;
  display: flex;
  gap: 12px;
  li a {
    color: #000;
    padding: 0 12px;
    margin: 0;
    cursor: pointer;
    &:hover,
    &:active,
    &:focus {
      color: ${({ theme: { brand } }) => brand};
      text-decoration: none;
    }
  }
`;

export const MobileContainer = styled.header`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  padding: 0 24px;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 1000;
  img {
    width: 100px;
    height: auto;
  }
  & > div:first-child {
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .toggler {
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      svg {
        color: ${({ theme: { brandDark } }) => brandDark};
        &:hover {
          color: ${({ theme: { brand } }) => brand};
        }
      }
    }
  }
  & > div:last-child {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;
export const MobileLinks = styled.ul`
  margin: 0;
  padding: 0 0 12px;
  list-style: none;
  font-size: 14px;
  line-height: 40npx;
  color: #000;
  display: flex;
  gap: 6px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  background-color: #fff;
  li {
    width: 100%;
    text-align: left;
  }
  li a {
    padding: 0 12px;
    margin: 0;
    cursor: pointer;
    color: #000;
    width: 100%;
    &:hover,
    &:active,
    &:focus {
      color: ${({ theme: { brand } }) => brand};
      text-decoration: none;
    }
  }
`;
