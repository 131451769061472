import { useState } from "react";
import Item from "./Item";
import { Container } from "./styles";
const faqs = [
  {
    heading: "What is algorithmic trading?",
    answer:
      "Algorithmic trading, often referred to as algo trading, is a method of executing financial transactions using computer algorithms. These algorithms are designed to analyze market data, identify trading opportunities, and execute orders at optimal prices and speeds. It allows for automated and systematic trading strategies.",
  },
  {
    heading: "How does Believe's algorithmic trading work?",
    answer:
      "Believe's algorithmic trading system uses advanced algorithms to analyze market data, including price movements, volume, and other indicators. It then executes trades based on predefined strategies, aiming to maximize returns and minimize risks. Our platform is designed to provide transparent and efficient trading solutions.",
  },
  {
    heading: "Is algorithmic trading suitable for beginners?",
    answer:
      "Algorithmic trading can be suitable for both beginners and experienced traders. Believe offers user-friendly interfaces and educational resources to help beginners get started. However, it's essential to understand the risks involved and start with a small investment.",
  },
  {
    heading: "What are the benefits of algorithmic trading?",
    answer:
      "Algorithmic trading offers several benefits, including faster execution of trades, reduced emotional bias, the ability to backtest strategies, and the potential for increased efficiency and profitability. It can also operate 24/7, taking advantage of global markets.",
  },
  {
    heading: "Is Believe's algorithmic trading platform secure?",
    answer:
      "Yes, Believe takes security seriously. We use advanced encryption and security measures to protect your data and transactions. Our platform undergoes regular security audits and is designed to meet industry standards for data protection.",
  },
  {
    heading: "How can I get started with Believe's algorithmic trading?",
    answer:
      "Getting started with Believe is easy. Sign up for an account, complete the necessary verification, and deposit funds. You can then explore our trading strategies, customize your settings, and start trading. Our support team is available to assist you throughout the process.",
  },
];

const FAQs = () => {
  const [active, setActive] = useState<number | null>(0);
  return (
    <Container>
      {faqs.map((faq, index) => (
        <Item
          {...faq}
          setActive={() =>
            setActive((prevState) => (prevState === index ? null : index))
          }
          active={active === index}
        />
      ))}
    </Container>
  );
};
export default FAQs;
