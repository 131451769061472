import "./App.css";
import About from "./components/about";
import Code from "./components/code";
import Contact from "./components/contact";
import FAQs from "./components/faqs";
import Footer from "./components/footer";
import Hero from "./components/hero";
import Navigation from "./components/navigation/Navigation";
import Reviews from "./components/reviews";
import Services from "./components/services";
import Strategies from "./components/strategies";

function App() {
  return (
    <div className="App">
      <Navigation />
      <Hero />
      <Services />
      <About />
      <Strategies />
      <Code />
      <Reviews />
      <FAQs />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
