import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";

const Customize = () => {
  const [active, setActive] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setActive((prevState) => (prevState === 1 ? 0 : 1));
    }, 3000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <Row>
      <Col className="trade__text" lg={5}>
        <h2>Customize your Strategies</h2>
        <p>With out experts help, you can create your own strategies</p>
        <ul className="list-unstyled">
          <li className={active === 0 ? "active" : ""}>
            <a href="#">
              <h6>Normal Trading</h6>
              <ul>
                <li>Trade whatever you like</li>
                <li>Best UI</li>
              </ul>
            </a>
          </li>
          <li className={active === 1 ? "active" : ""}>
            <a href="#">
              <h6>Option Trading</h6>
              <ul>
                <li>Trade in Options</li>
                <li>With Ease</li>
              </ul>
            </a>
          </li>
        </ul>
      </Col>
      <Col lg={7} className="trade__image">
        <img src={`/images/customize-${active}.png`} alt="" />
      </Col>
    </Row>
  );
};
export default Customize;
