import * as React from "react";
const NavOpen = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M3.75 6.75h16.5M3.75 12H12m-8.25 5.25h16.5"
      stroke="#0F172A"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default NavOpen;
