import { ItemContainer, Heading, Text } from "./styles";
type Props = {
  price: number;
  name: string;
  src: string;
  onClick: () => void;
};
const Item = ({ price, name, src, onClick }: Props) => {
  return (
    <ItemContainer onClick={() => onClick && onClick()}>
      <img style={{ width: "50%", height: "auto" }} alt="Icon" src={src} />
      <Heading>{name}</Heading>
      <Text>{price} INR/Monthly(+ 18% GST)</Text>
    </ItemContainer>
  );
};
export default Item;
