import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";

const Trade = () => {
  const [active, setActive] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setActive((prevState) => (prevState === 2 ? 0 : prevState + 1));
    }, 3000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <Row>
      <Col lg={7} className="trade__image">
        <img src={`/images/trade-${active}.png`} alt="" />
      </Col>
      <Col className="trade__text" lg={5}>
        <h2>Get Started In 3 Steps</h2>
        <p>We have designed our platform keeping you in mind.</p>
        <ul className="list-unstyled">
          <li className={active === 0 ? "active" : ""}>
            <a href="#">
              <h6>Create an Account</h6>
              <ul>
                <li>Easy Registeration</li>
                <li>Under 5 mins.</li>
              </ul>
            </a>
          </li>
          <li className={active === 1 ? "active" : ""}>
            <a href="#">
              <h6>Link your Broker</h6>
              <ul>
                <li>30+ brokers</li>
                <li>Easy Connection</li>
              </ul>
            </a>
          </li>
          <li className={active === 2 ? "active" : ""}>
            <a href="#">
              <h6>Create a Strategy</h6>
              <ul>
                <li>20+ Pre defined</li>
                <li>Customizable</li>
              </ul>
            </a>
          </li>
        </ul>
      </Col>
    </Row>
  );
};
export default Trade;
