import styled from "styled-components";
export const Container = styled.div`
  width: 100%;
  height: fit-content;
  & > div {
    max-width: 1392px;
    padding: 30px;
    min-height: 400px;
    margin: 80px auto 98px;
    border-radius: 15px;
    background-color: ${({ theme: { brandLight } }) => brandLight};
    display: flex;
    align-items: center;
    box-sizing: border-box;
    @media (max-width: 996px) {
      width: 100%;
      padding: 48px 12px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      overflow: hidden;
    }
    .hero-img {
      width: 90%;
      margin: 0 auto;
      display: block;
      @media (max-width: 996px) {
        width: 320px;
        height: auto;
        position: relative;
        top: 80px;
      }
    }
    & > div:first-child {
      position: relative;
    }
  }
`;
export const Background = styled.div`
  background-color: ${({ theme: { brandDark } }) => brandDark};
  position: absolute;
  top: 0;
  bottom: 0;
  left: 82px;
  right: 0;
  height: 100%;
  max-width: 605px;
  border-radius: 12px;
  transform: rotate(-5deg);
  z-index: 1;
  @media (max-width: 996px) {
    display: none;
  }
`;
export const TextContainer = styled.div`
  padding: 48px 64px;
  display: flex;
  flex-direction: column;
  max-width: 605px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
  background-color: #fff;
  border-radius: 12px;
  min-height: 250px;
  @media (max-width: 996px) {
    align-items: center;
    padding: 24px;
  }
  .send {
    color: #74cc00;
    font-size: 10px;
    line-height: 1.4;
    position: relative;
    padding-bottom: 28px;
    margin-bottom: 24px;
    margin-top: 12px;
    @media (max-width: 996px) {
      margin-top: 24px;
      text-align: center;
    }
  }
  h2 {
    font-size: 27px;
    color: ${({ theme: { brandDark } }) => brandDark};
    font-weight: 700;
    line-height: 1.17;
    margin-bottom: 24px;
    span {
      font-weight: 400;
    }
    @media (max-width: 996px) {
      text-align: center;
      font-size: 24px;
      line-height: 1.25;
    }
  }
  .terms {
    font-size: 10px;
    color: #666;
    line-height: 1.4;
    position: relative;
    padding-bottom: 28px;
    margin-bottom: 24px;
    margin-top: 12px;
    @media (max-width: 996px) {
      margin-top: 24px;
      text-align: center;
    }
    &::after {
      content: "";
      display: inline-block;
      background-color: rgba(97, 57, 159, 0.3);
      height: 2px;
      min-width: 164px;
      position: absolute;
      bottom: 0;
      left: 0;
      @media (max-width: 996px) {
        display: none;
      }
    }
  }
`;
export const Standout = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  height: fit-content;
  width: 100%;
  justify-content: space-between;
  @media (max-width: 996px) {
    flex-wrap: wrap;
    row-gap: 24px;
  }
  li {
    padding: 0 15px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-pack: center;
    justify-content: center;
    @media (max-width: 996px) {
      width: 50%;
      box-sizing: border-box;
    }
    h5 {
      font-size: 18px;
      color: #444;
      line-height: 1.77;
      margin: 0;
      font-weight: 700;
      display: flex;
      @media (max-width: 996px) {
        text-align: center;
        justify-content: center;
      }
    }
    span {
      align-self: center;
      margin-left: 2px;
      margin-bottom: 4px;
      font-size: 12px;
      line-height: 1.66;
      color: #3d3d3dbf;
      font-weight: 400;
      white-space: nowrap;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;
