import RenderIf from "../RenderIf";
import useWindowDimensions from "../hooks/useWindowDimensions";
import Desktop from "./Desktop";
import Mobile from "./Mobile";

const About = () => {
  const { width } = useWindowDimensions();
  return (
    <>
      <RenderIf condition={width < 996}>
        <Mobile />
      </RenderIf>
      <RenderIf condition={width > 995}>
        <Desktop />
      </RenderIf>
    </>
  );
};
export default About;
