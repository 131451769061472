import styled from "styled-components";
export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  padding: 24px 5% 0;
  flex-direction: column-reverse;
  @media (max-width: 996px) {
    flex-direction: column;
  }
  div {
    width: 100%;
    border-top: 1px solid ${({ theme: { brandLight } }) => brandLight};
    padding: 24px;
    @media (max-width: 996px) {
      padding-top: 6px;
    }
    p {
      font-size: 16px;
      color: ${({ theme: { brand } }) => brand};
      font-weight: 300;
      margin-bottom: 25px;
      line-height: 1.5;
      text-align: center;
    }
  }
  & > p {
    color: #444;
    font-weight: 300;
    margin-bottom: 25px;
    line-height: 1.5;
    text-align: justify;
    font-size: 12px;
    @media (max-width: 996px) {
      font-size: 14px;
    }
  }
`;
