import { Col, Row } from "react-bootstrap";
import Button from "../Button";
import RenderIf from "../RenderIf";
import useWindowDimensions from "../hooks/useWindowDimensions";
import Item from "./Item";
import { Container, ItemsContainer } from "./styles";
import { useEffect, useRef, useState } from "react";

const strategies = [
  { name: "Price Breakout", price: 19000, src: "/images/strategies_2.svg" },
  {
    name: "Time Range Breakout",
    price: 15000,
    src: "/images/strategies_3.svg",
  },
  { name: "ADL", price: 23000, src: "/images/strategies_1.svg" },
  { name: "Awesome Oscillator", price: 19000, src: "images/strategies_4.svg" },
  { name: "BB", price: 16400, src: "images/strategies_5.svg" },
  { name: "CCI", price: 15900, src: "images/strategies_6.svg" },
  { name: "Factral MA", price: 17500, src: "images/strategies_8.svg" },
  { name: "Ichimoku Cloud", price: 30000, src: "images/strategies_7.svg" },
  { name: "Moneyflow Index", price: 15800, src: "images/strategies_9.svg" },
  {
    name: "Stochastic Oscillator",
    price: 24900,
    src: "images/strategies_10.svg",
  },
  {
    name: "TESMA",
    price: 18000,
    src: "images/strategies_11.svg",
  },
  {
    name: "VWAP",
    price: 27000,
    src: "images/strategies_12.svg",
  },
  {
    name: "Volume Profile",
    price: 19500,
    src: "images/strategies_1.svg",
  },
  {
    name: "Stochastic Oscillator",
    price: 17900,
    src: "images/strategies_2.svg",
  },
  {
    name: "Moving Average",
    price: 15000,
    src: "/images/strategies_4.svg",
  },
  {
    name: "RSI",
    price: 17000,
    src: "/images/strategies_5.svg",
  },
  {
    name: "Supertrend",
    price: 16000,
    src: "/images/strategies_3.svg",
  },
  {
    name: "Bolinger Band",
    price: 17000,
    src: "/images/strategies_1.svg",
  },
  {
    name: "MA Cross",
    price: 15000,
    src: "/images/strategies_8.svg",
  },
  {
    name: "Parabolic SAR",
    price: 17000,
    src: "/images/strategies_11.svg",
  },
  {
    name: "Fibonacci",
    price: 18000,
    src: "/images/strategies_10.svg",
  },
  {
    name: "MACD",
    price: 19000,
    src: "/images/strategies_7.svg",
  },
];

const Strategies = () => {
  const [expanded, setExpanded] = useState(6);
  const { width } = useWindowDimensions();
  const [clicked, setClicked] = useState<number | null>(null);
  const [currentPaymentState, setCurrentPaymentState] = useState(0);
  const paymentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (clicked && paymentRef.current) paymentRef?.current.scrollIntoView();
  }, [clicked]);
  return (
    <Container id="strategies">
      <h2>Top Rated Strategies</h2>
      <ItemsContainer>
        {strategies.map((strategy, index) => (
          <RenderIf condition={index < expanded}>
            <Item
              key={strategy.name}
              {...strategy}
              onClick={() =>
                setClicked((prevState) => (prevState === index ? null : index))
              }
            />
          </RenderIf>
        ))}
      </ItemsContainer>
      <RenderIf condition={expanded < 21}>
        <div
          onClick={() => setExpanded((prevState) => prevState + 3)}
          style={{ width: width < 996 ? "90%" : "30%" }}
        >
          <Button
            variant="squareSecondary"
            height={45}
            text="See More"
            width={width < 996 ? "100%" : ""}
          />
        </div>
      </RenderIf>
      <RenderIf condition={!!clicked || clicked === 0}>
        <div className="payment" ref={paymentRef}>
          <div className="head">
            <h2>Interested in Subscription?</h2>
            <p>
              You can use any kind of online payment to start your subscription.
              Scan the QR code or else make payment to our bank account for
              subscribing.
            </p>
          </div>
          <Row className="body">
            <Col className="left" lg={7}>
              <h3>Pay Via Upi (Recommended)</h3>
              <div className="icons">
                <img src="/images/paytm.png" alt="" />
                <img src="/images/phonepe.png" alt="" />
                <img src="/images/googlepay.png" alt="" />
                <img src="/images/upi.svg" alt="" />
              </div>
              <p className="note">
                Note: Once you make payment, please take the screenshot of it
                and don't forget to share the payment receipt on our email or
                mobile number. Payment without the screenshot will not
                considered as subscription.
              </p>
              <p className="or">Or</p>
              <h3
                onClick={() =>
                  setCurrentPaymentState((prevState) => (!prevState ? 1 : 0))
                }
                className="no-upi"
              >
                {`${
                  currentPaymentState == 0
                    ? "Don't have UPI Account? "
                    : "Want to go with UPI? "
                }`}
                <span className="no-upi-span">Click Here</span>
              </h3>
            </Col>
            <Col className="right" lg={5}>
              <RenderIf condition={currentPaymentState === 0}>
                <div className="scanner">
                  <img src="/images/scanner.png" />
                </div>
              </RenderIf>
              <RenderIf condition={currentPaymentState === 1}>
                <div className="bank-details">
                  <ul>
                    <li>Name: BELIVE TECHNOLOGY DEVELOPERS</li>
                    <li>Bank: HDFC BANK</li>
                    <li>Acc No: 50200083145064</li>
                    <li>IFSC Code: HDFC0000404</li>
                    <li>Branch: Vijay Nagar, Indore</li>
                    <li>Type: Current Account</li>
                  </ul>
                </div>
              </RenderIf>
            </Col>
            <img className="city-bg" src="/images/city-bg.png" />
          </Row>
        </div>
      </RenderIf>
    </Container>
  );
};
export default Strategies;
