import { Container } from "./styles";

const WebApp = () => {
  return (
    <Container active={2}>
      <p style={{ fontSize: "17px", marginBottom: "32px" }}>
        Transform your trading strategies into reality with our Pine Script
        coding services. Pine Script is the scripting language used for custom
        indicator and strategy development on TradingView, and our team of
        skilled Pine Script developers is here to help you harness its full
        potential.
      </p>
      <h2 style={{ fontSize: "20px" }}>Website Coding:</h2>
      <p style={{ fontSize: "17px", marginBottom: "32px" }}>
        Our expert web developers are dedicated to crafting websites that not
        only captivate your audience but also deliver seamless user experiences.
        From e-commerce platforms to informative blogs and corporate websites,
        we use cutting-edge technologies like HTML5, CSS3, JavaScript, and
        responsive design to ensure your online presence is both visually
        stunning and highly functional.
      </p>
      <h2 style={{ fontSize: "20px" }}>App Coding:</h2>
      <p style={{ fontSize: "17px", marginBottom: "24px" }}>
        Mobile apps have become an integral part of modern life, and our app
        development team is at the forefront of this revolution. Whether you
        need a native iOS, Android, or cross-platform app, we leverage languages
        like Java, Kotlin, Swift, and React Native to create apps that resonate
        with your target audience and provide exceptional performance.
      </p>
    </Container>
  );
};
export default WebApp;
