import { useState, useEffect } from "react";
import { Container, Indicators } from "./styles";
import Item from "./Item";
import { motion, AnimatePresence } from "framer-motion";
const testimonials = [
  {
    text: "",
    name: "",
    since: "",
  },
  {
    text: "This algo trading platform is a game-changer! I've seen consistent profits since I started in 2021.",
    name: "Rahul Gupta",
    since: "TRADING ON BELIEVE SINCE 2021",
  },
  {
    text: "Believe's algorithmic trading has simplified my investment journey. Impressive results!",
    name: "Priya Sharma",
    since: "TRADING ON BELIEVE SINCE 2019",
  },
  {
    text: "I'm thrilled with the returns I've achieved with Believe's algo trading. Truly impressive!",
    name: "Amit Patel",
    since: "TRADING ON BELIEVE SINCE 2020",
  },
  {
    text: "Trustworthy and transparent. I've been trading with Believe since 2022 and never looked back.",
    name: "Sneha Reddy",
    since: "TRADING ON BELIEVE SINCE 2022",
  },
  {
    text: "A friend recommended Believe, and I'm glad I listened. Great returns and excellent support!",
    name: "Anil Kumar",
    since: "TRADING ON BELIEVE SINCE 2018",
  },
];

const Mobile = () => {
  const [active, setActive] = useState(1);
  useEffect(() => {
    const interval = setInterval(() => {
      setActive((prevActive) => (prevActive === 5 ? 1 : prevActive + 1));
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <Container>
      <AnimatePresence mode="wait">
        <motion.div
          key={active}
          initial={{ x: 300 }}
          animate={{ x: 0 }}
          exit={{ x: -300 }}
          transition={{ stiffness: 60 }}
          style={{
            width: "fit-content",
            height: "fit-content",
            margin: "98px 0",
          }}
        >
          <Item {...testimonials[active]} key={testimonials[active].name} />
        </motion.div>
      </AnimatePresence>
      <Indicators>
        <div
          onClick={() => setActive(1)}
          className={active === 1 ? "active" : ""}
        ></div>
        <div
          onClick={() => setActive(2)}
          className={active === 2 ? "active" : ""}
        ></div>
        <div
          onClick={() => setActive(3)}
          className={active === 3 ? "active" : ""}
        ></div>
        <div
          onClick={() => setActive(4)}
          className={active === 4 ? "active" : ""}
        ></div>
        <div
          onClick={() => setActive(5)}
          className={active === 5 ? "active" : ""}
        ></div>
      </Indicators>
    </Container>
  );
};
export default Mobile;
