import SliderMobile from "./SliderMobile";
import { MobileContainer } from "./styles";
const sliderItems = [
  {
    name: "Algo Trading",
    content:
      "Our team of experts designs and implements cutting-edge algorithmic trading strategies tailored to your specific financial goals.",
    image: "/images/trade-1.png",
  },
  {
    name: "Pre Defined",
    content:
      "We have 20+ Pre-defined highly effective and most common strategies available on our platform that makes trading effortless.",
    image: "/images/trade-2.png",
  },
  {
    name: "MQL Coding",
    content:
      "MQL (Meta Quote language) is the most popular language for algo trading in various markets, we are expert in coding the MQL Codes.",
    image: "/images/trade-0.png",
  },
  {
    name: "Pine Script Coding",
    content:
      "Pine Script code is mostly get used in Trading View. If you have an idea, we are able to make it real in Pine script code.",
    image: "/images/customize-0.png",
  },
  {
    name: "Web/App Development",
    content:
      "We are expert in any kind of static or dynamic website or Web app Design and development. We make 100+ Website so far.",
    image: "/images/customize-1.png",
  },
];

const Mobile = () => {
  return (
    <MobileContainer id="services">
      <div className="heading">
        <h2>Can always Believe</h2>
        <p>Trade, Customize, Develop. We got all covered.</p>
      </div>
      <SliderMobile sliderItems={sliderItems.filter((_, i) => i <= 2)} />
      <SliderMobile
        lastIndex={1}
        sliderItems={sliderItems.filter((_, i) => i > 2)}
      />
    </MobileContainer>
  );
};
export default Mobile;
