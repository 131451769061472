import styled from "styled-components";
export const MobileContainer = styled.div`
  width: 100%;
  padding: 0 16px;
  padding: 30px 0 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .heading h2 {
    font-size: 24px;
    color: ${({ theme: { brandDark } }) => brandDark};
    line-height: 1.3;
    letter-spacing: -0.035em;
    font-weight: 700;
    margin-top: 0;
    text-align: center;
  }
  .heading p {
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: -0.01em;
    color: rgba(61, 61, 61, 0.75);
    text-align: center;
  }
  .heading {
    margin-bottom: 32px;
  }
`;
export const SliderMobileContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 36px;
  ul {
    margin: 0;
    padding: 0;
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  li {
    margin: 0;
    padding: 0 16px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 33.33%;
  }
  h6 {
    font-size: 18px;
    color: #666;
    font-weight: 400;
    line-height: 1.47;
    margin: 0 0 12px;
    cursor: pointer;
    transition: width 0.5s;
    position: relative;
    display: inline-block;
    text-align: center;
    &.active {
      color: #000;
      font-weight: 600;
    }
  }
  .indicator {
    background-color: ${({ theme: { brandDark } }) => brandDark};
    height: 3px;
  }
`;
export const Content = styled.div`
  width: 100%;
  background-color: #f8f7f5;
  border-radius: 18px;
  div {
    padding: 32px 10px;
    text-align: center;
    background: #f8f7f5;
    margin: 0;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      font-size: 14px;
      line-height: 1.5;
      color: #000;
      letter-spacing: -0.01em;
      position: relative;
      margin-right: 15px;
      padding: unset;
      display: unset;
      margin-bottom: 0;
      opacity: 1;
    }
  }
`;

export const Container = styled.div`
  padding: 11px 0 0;
  a:hover,
  a:active {
    text-decoration: none;
  }
  .inner {
    max-width: 1170px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }
  .nav {
    top: 118px;
    padding-bottom: 0;
    position: sticky;
    top: 70px;
    z-index: 1;
  }
  .nav div {
    display: flex;
    border-bottom: 1px solid #f2ecfd;
    position: relative;
    justify-content: center;
    margin: 0 auto;
    background-color: var(--white);
    z-index: 10;
    padding: 0;
    list-style: none;
    width: 100%;
    padding-bottom: 12px;
    flex-direction: column;
    align-items: center;
    &::after {
      content: "";
      display: inline-block;
      background: radial-gradient(
        75.85% 75.85% at 50% -39.83%,
        #e5ddf4 0,
        rgba(242, 236, 253, 0) 100%
      );
      min-height: 118px;
      width: 100%;
      position: absolute;
      top: 100%;
      z-index: 1;
    }
    h2 {
      font-size: 24px;
      color: #41246e;
      line-height: 1.3;
      letter-spacing: -0.035em;
      font-weight: 700;
      margin-top: 0;
      text-align: center;
      margin-bottom: 24px;
    }
    p {
      margin: 0 auto;
      text-align: center;
      width: 80%;
      color: #666;
      margin-bottom: 20px;
    }
  }
  .nav li {
    padding: 24px 38px;
    a {
      font-size: 20px;
      color: ${({ theme: { brandDark } }) => brandDark};
      font-weight: 600;
      line-height: 1.25;
      letter-spacing: -0.035em;
      margin: 0;
      opacity: 0.3;
      text-decoration: none;
    }
  }
  .trade {
    padding: 97px 0 0;
    min-height: 600px;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .trade__text {
    h2 {
      font-size: 32px;
      color: ${({ theme: { brandDark } }) => brandDark};
      line-height: 1.3;
      letter-spacing: -0.035em;
      font-weight: 700;
      margin-top: 0;
    }
    p {
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: -0.01em;
      color: rgba(61, 61, 61, 0.75);
      margin-bottom: 56px;
    }
    ul {
      margin-bottom: 32px;
      li {
        padding: 0 25px;
        display: inline-block;
        margin-bottom: 24px;
        a h6 {
          font-size: 18px;
          color: #000;
          font-weight: 600;
          line-height: 1.44;
          margin: 0;
          opacity: 0.5;
        }
        a ul {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 0;
          padding: 0;
        }
        a li {
          font-size: 14px;
          line-height: 1.5;
          color: #000;
          letter-spacing: -0.01em;
          position: relative;
          margin-right: 15px;
          padding: unset;
          display: unset;
          margin-bottom: 0;
          opacity: 0.5;
          &::after {
            content: "";
            display: inline-block;
            background-color: #444;
            width: 1px;
            position: absolute;
            right: -8px;
            top: 50%;
            transform: translateY(-50%);
            min-height: 18px;
          }
          &:last-child::after {
            display: none;
          }
        }
      }
      .active::before {
        content: "";
        display: inline-block;
        background-color: ${({ theme: { brandDark } }) => brandDark};
        height: 100%;
        width: 4px;
        position: absolute;
        left: 0;
        height: auto;
        min-height: 63px;
      }
      .active {
        a h6 {
          opacity: 1;
        }
        a li {
          opacity: 1;
        }
      }
    }
  }
  .trade__image {
    img {
      max-width: 100%;
      display: block;
      height: auto;
    }
  }
`;
