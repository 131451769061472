import styled from "styled-components";
export const ItemContainer = styled.div`
  border-bottom: 1px solid #999;
  padding: 12px 0;
  width: 100%;
  background-color: #fff;
  &:last-child {
    border-bottom: none;
  }
  & .heading {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 6px 0px;
    overflow: hidden;
    width: 100%;
    p {
      font-size: 16px;
      line-height: 1.47;
      color: #000;
      font-weight: 600;
      margin: 0 0 6px;
      padding-bottom: 17px;
      cursor: pointer;
      transition: width 0.5s;
      position: relative;
      display: inline-block;
      text-align: left;
      max-width: 80%;
      padding: 6px 12px 12px 0;
    }
    .answer {
      p {
        font-size: 12px;
        line-height: 1.47;
        color: #000;
        font-weight: 400;
        padding-bottom: 17px;
        cursor: pointer;
        transition: width 0.5s;
        position: relative;
        display: inline-block;
        text-align: left;
        max-width: 80px;
        padding: 0 12px;
      }
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  padding: 158px 20%;
  @media (max-width: 996px) {
    padding: 98px 5%;
  }
`;
