import styled from "styled-components";
export const Container = styled.div`
  background-color: ${({ theme: { brandLight } }) => brandLight};
  padding: 98px 64px;
  box-sizing: border-box;
  width: 100%;
  max-width: 1397px;
  margin: 0 auto;
  border-radius: 20px;
  @media (max-width: 996px) {
    width: 100%;
    border-radius: 0;
  }
  h2 {
    font-size: 32px;
    color: ${({ theme: { brandDark } }) => brandDark};
    line-height: 1.3;
    letter-spacing: -0.035em;
    font-weight: 700;
    margin-top: 0;
    text-align: left;
    @media (max-width: 996px) {
      text-align: center;
      font-size: 24px;
    }
  }
  p {
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: -0.01em;
    color: rgba(61, 61, 61, 0.75);
    text-align: left;
    max-width: 70%;
    margin-right: auto;
    @media (max-width: 996px) {
      max-width: 85%;
      text-align: center;
      font-size: 14px;
    }
  }
  .form {
    margin-top: 48px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 12px;
    width: 70%;
    @media (max-width: 996px) {
      margin: 0 auto;
      width: 100%;
    }
    input,
    textarea {
      width: 100%;
      height: 45px;
      border-radius: 6px;
      border: 1px solid ${({ theme: { brandDark } }) => brandDark};
      padding: 0 12px;
      box-sizing: border-box;
      position: relative;
      &:focus,
      &:active {
        outline: 0;
        border: 1px solid ${({ theme: { brand } }) => brand};
      }
      &::placeholder {
        font-size: 16px;
        font-weight: 500;
      }
    }
    textarea {
      padding-top: 8px;
      height: 100px;
      margin-bottom: 12px;
    }
  }
`;
